import { getTheme } from '@/constants/theme';

const colorsB2b = {
  footerTopBackground: '#182028',
  footerTopText: '#93999e',
  footerBottomBackground: '#fff',
  footerBottomText: '#93999e',
  footerBottomLine: 'transparent',
  footerSocialIcon: '#182028',
  footerSocialIconHover: '#182028',
  footerSocialIconBackground: '#fff',
  footerSocialIconBackgroundHover: 'rgba(255, 255, 255, 0.6)',
  footerNavHeader: '#fff',
  footerNavHeaderHover: '#fff',
  footerNavChild: '#fff',
  footerNavChildHover: 'rgba(255, 255, 255, 0.6)',
} as const;

const colorsHisun = {
  footerTopBackground: '#333',
  footerTopText: '#adadad',
  footerBottomBackground: '#333',
  footerBottomText: '#858585',
  footerBottomLine: '#5c5c5c',
  footerSocialIcon: '#333',
  footerSocialIconHover: '#333',
  footerSocialIconBackground: '#858585',
  footerSocialIconBackgroundHover: '#fff',
  footerNavHeader: '#fff',
  footerNavHeaderHover: '#fff',
  footerNavChild: '#fff',
  footerNavChildHover: '#858585',
} as const;

const colorsJac = {
  footerTopBackground: '#1d1d1b',
  footerTopText: '#adadad',
  footerBottomBackground: '#1d1d1b',
  footerBottomText: '#adadad',
  footerBottomLine: '#5c5c5c',
  footerSocialIcon: '#1d1d1b',
  footerSocialIconHover: '#fff',
  footerSocialIconBackground: '#fff',
  footerSocialIconBackgroundHover: '#cc000c',
  footerNavHeader: '#fff',
  footerNavHeaderHover: '#949494',
  footerNavChild: '#fff',
  footerNavChildHover: '#949494',
} as const;

const colorsJetour = {
  footerTopBackground: '#4c4c4c',
  footerTopText: '#adadad',
  footerBottomBackground: '#4c4c4c',
  footerBottomText: '#949494',
  footerBottomLine: 'rgba(255, 255, 255, 0.2)',
  footerSocialIcon: '#4c4c4c',
  footerSocialIconHover: '#68a598',
  footerSocialIconBackground: '#949494',
  footerSocialIconBackgroundHover: '#fff',
  footerNavHeader: '#fff',
  footerNavHeaderHover: '#68a598',
  footerNavChild: '#fff',
  footerNavChildHover: '#949494',
} as const;

export const componentColors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsB2b;
  }
})();
