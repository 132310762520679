import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { media } from '@/utils/mixin';
import {
  selectMobileMenuOpened,
  setMobileMenuOpened,
} from '@/store/reducers/layout';
import { colors } from '@/constants/theme';

import Info from './components/Info';
import NewCars from './components/SubMenu/NewCars';
import MenuItem from './MenuItem';

export interface MenuItemModel {
  label: string;
  link: string;
  subMenu?: Nullable<React.ReactNode>;
}

const menuDataList: Array<MenuItemModel> = [];

const onClose = () => {
  document.body.style.overflow = 'auto';
};

function MobileMenu() {
  const headerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];

  const isOpen = useTypedSelector(selectMobileMenuOpened);
  const dispatch = useTypedDispatch();

  const menuList = headerMenu.map((item) => {
    return {
      label: item.label,
      link: item.link,
      subMenu: item.children.length ? (
        <NewCars menuItems={item.children} />
      ) : null,
    };
  }) as typeof menuDataList;

  function handleDropDownToggle() {
    dispatch(setMobileMenuOpened(!isOpen));

    if (!isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Component>
      <Button type="button" onClick={handleDropDownToggle}>
        <Text isActive={isOpen}>Меню</Text>
        <Line1 isActive={isOpen} />
        <Line2 isActive={isOpen} />
        <Line3 isActive={isOpen} />
      </Button>

      <Menu isOpen={isOpen}>
        <MenuList>
          {menuList.map((menuItem, index) => (
            <MenuItem {...menuItem} key={index} onMenuItemClick={onClose} />
          ))}
        </MenuList>
        <Info onInfoItemClick={onClose} />
      </Menu>
    </Component>
  );
}

export default MobileMenu;

const Component = styled.div`
  margin-left: 5px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  width: 40px;
  height: 40px;

  ${media.mobileSmall(css`
    width: 25px;
    height: 25px;
  `)}

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Text = styled.span<{ isActive: boolean }>`
  display: block;

  font-weight: 700;
  font-size: 8px;
  line-height: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1px;

  color: ${colors.white};

  transition: 0.15s;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 0;
    `}
`;

const Line = styled.span`
  display: block;
  width: 26px;
  border-top: 2px solid ${colors.white};
  transform: translate3d(0, 0, 0) scaleX(1);
  transition: 0.15s;
`;

const Line1 = styled(Line)<{ isActive: boolean }>`
  margin-top: 2px;

  transform: translate3d(0, 0, 0) scaleX(1);

  ${({ isActive }) =>
    isActive &&
    css`
      transform: translate3d(0, 2px, 0) rotate(45deg);
    `}
`;

const Line2 = styled(Line)<{ isActive: boolean }>`
  margin-top: 4px;

  transform: translate3d(0, 0, 0) scaleX(1);

  ${({ isActive }) =>
    isActive &&
    css`
      transform: translate3d(0, -4px, 0) scaleX(0);
    `}
`;

const Line3 = styled(Line)<{ isActive: boolean }>`
  width: 20px;
  margin-top: 4px;
  margin-right: 6px;

  transform: translate3d(0, 0, 0) scaleX(1);

  ${({ isActive }) =>
    isActive &&
    css`
      width: 26px;
      margin-right: 0;
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    `}
`;

const Menu = styled.nav<{ isOpen: boolean }>`
  display: none;
  -webkit-transform: translateZ(0px);
  position: fixed;

  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;

  overflow: scroll;

  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 60px);

  background: ${(props) => props.theme.white};

  ${media.mobile(css`
    top: 60px;
  `)}

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`;

const MenuList = styled.ul``;
