import { getSubdomain } from '@/utils/common';
import { usePageType } from '@/hooks/usePageType';

import { Footer, FooterPurchase } from './components/Footer';

export function FooterMain() {
  const { template } = usePageType();

  if (template === 'subdomain-home') {
    return <FooterPurchase subdomain={getSubdomain()} />;
  }

  return <Footer />;
}
