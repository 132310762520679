import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';

import { CollaborateLogos } from './common.components';

function Logos() {
  return (
    <Component to="/">
      <CollaborateLogos />
    </Component>
  );
}

export default Logos;

const Component = styled(Link)`
  display: flex;
  align-items: center;
  justify-items: center;
`;
