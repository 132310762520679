import React from 'react';

import { getTheme } from '@/constants/theme';

import {
  B2bDealerWebHeader,
  HisunDealerWebHeader,
  JacDealerWebHeader,
  JetourDealerWebHeader,
} from './common.components';

function DealerWebHeader() {
  const theme = getTheme();

  switch (theme) {
    case 'b2b':
      return <B2bDealerWebHeader />;
    case 'hisun':
      return <HisunDealerWebHeader />;
    case 'jac':
      return <JacDealerWebHeader />;
    case 'jetour':
      return <JetourDealerWebHeader />;
    default:
      return <React.Fragment />;
  }
}

export default DealerWebHeader;
