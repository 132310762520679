import React from 'react';
import styled, { css } from 'styled-components';

import { componentColors } from '@/layout/components/DealerWebHeader/constants';
import Link from '@/components/Link';
import { useHover } from '@/hooks/useHover';
import Models from '@/layout/components/DealerWebHeader/components/Models';

import { ModelsDropdownProps } from './types';

function ModelsDropdown({ label, link, models, onClick }: ModelsDropdownProps) {
  const { isHovering, getHoverProps } = useHover({
    mouseLeaveDelay: 300,
  });

  return (
    <Component {...getHoverProps()}>
      <StyledLink
        to={link}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
          onClick?.(link, event)
        }
      >
        {label}
      </StyledLink>
      <Content isOpen={isHovering}>
        <ContentContainer>
          <Models models={models} />
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default ModelsDropdown;

const Component = styled.div``;

const StyledLink = styled(Link)`
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  color: ${componentColors.headerNav};

  &:hover {
    opacity: 0.6;
  }
`;

const Content = styled.div<{ isOpen: boolean }>`
  z-index: 9998;
  display: none;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  padding: 40px 0;
  background: ${componentColors.headerBackground};
  box-shadow: ${(props) => props.theme.blackShadow300};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`;

const ContentContainer = styled.div`
  max-width: 900px;
  padding: 0 40px;
  margin: 0 auto;
`;
