import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';

interface Props {
  cars: Array<{ label: string; icon: string; link: string }>;
}

function Cars({ cars }: Props) {
  return (
    <Component>
      {cars.map((car, index) => (
        <CarItem key={index}>
          <CarLink to={car.link}>
            <CarIcon>
              <img loading="lazy" src={car.icon} alt={car.label} />
            </CarIcon>
            <CarText>{car.label}</CarText>
          </CarLink>
        </CarItem>
      ))}
    </Component>
  );
}

export default Cars;

const Component = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;

const CarItem = styled.li``;

const CarText = styled.span`
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.15s;
`;

const CarLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.main};

  &:hover {
    ${CarText} {
      border-bottom-color: ${(props) => props.theme.main};
    }
  }
`;

const CarIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-right: 8px;
  background: ${colors.white};
  border-radius: 10px;
  padding: 2px;

  img {
    display: block;
    width: 32px;
    height: 32px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
