import React from 'react';

import { isStringGuard } from '@tager/web-core';

import { getTheme } from '@/constants/theme';

import * as S from './Preloader.style';
import AtlantmSecondaryLogo from './assets/atlantm-secondary-logo.svg';
import AtlantmPrimaryLogo from './assets/atlantm-primary-logo.svg';
import B2bPrimaryLogo from './assets/b2b-primary-logo.svg';
import B2bSecondaryLogo from './assets/b2b-secondary-logo.svg';
import HisunPrimaryLogo from './assets/hisun-primary-logo.svg';
import HisunSecondaryLogo from './assets/hisun-secondary-logo.svg';
import JacPrimaryLogo from './assets/jac-primary-logo.svg';
import JacSecondaryLogo from './assets/jac-secondary-logo.svg';
import JetourPrimaryLogo from './assets/jetour-primary-logo.svg';
import JetourSecondaryLogo from './assets/jetour-secondary-logo.svg';

export function isPreloaderEnabled() {
  const isEnabledEnv = process.env.NEXT_PUBLIC_SPLASHSCREEN_ENABLED;

  return (
    isStringGuard(isEnabledEnv) &&
    (isEnabledEnv.toLowerCase() === 'true' || isEnabledEnv === '1')
  );
}

export function getPreloaderLogos() {
  const theme = getTheme();

  switch (theme) {
    case 'main':
      return (
        <React.Fragment>
          <AtlantmPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <AtlantmSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'b2b':
      return (
        <React.Fragment>
          <B2bPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <B2bSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'hisun':
      return (
        <React.Fragment>
          <HisunPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <HisunSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'jac':
      return (
        <React.Fragment>
          <JacPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <JacSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'jetour':
      return (
        <React.Fragment>
          <JetourPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <JetourSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <AtlantmPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <AtlantmSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
  }
}
