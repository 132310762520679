import React from 'react';
import styled, { css } from 'styled-components';

import Preloader from '@/components/Preloader';
import { getTheme } from '@/constants/theme';

import Header from './components/Header';
import { FooterMain } from './common.components';
import DealerWebHeader from './components/DealerWebHeader';
import DealerWebFooter from './components/DealerWebFooter';

interface Props {
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
  pdfPrint?: boolean;
  className?: string;
}

function Layout({ children, isPreloaderHidden, pdfPrint, className }: Props) {
  const theme = getTheme();

  switch (theme) {
    case 'main':
      return (
        <Container className={className} pdfPrint={pdfPrint}>
          <Preloader hidden={isPreloaderHidden} />
          <Header />
          <Main>{children}</Main>
          <FooterMain />
        </Container>
      );

    default:
      return (
        <Container className={className} pdfPrint={pdfPrint}>
          <Preloader hidden={isPreloaderHidden} />
          <DealerWebHeader />
          <Main>{children}</Main>
          <DealerWebFooter />
        </Container>
      );
  }
}

export default Layout;

const Container = styled.div<{ pdfPrint?: boolean }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.white};
  overflow: clip;

  ${({ pdfPrint }) =>
    pdfPrint &&
    css`
      @media print {
        display: none;
      }
    `}
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;
