import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { selectMainMenuBanners } from '@/store/reducers/banners';
import { handleMobileMenuClose } from '@/utils/common';

interface Props {
  label: string;
  url: string;
}

function Banner({ label, url }: Props) {
  const mainMenuBanners = useTypedSelector(selectMainMenuBanners);
  const isMobile = useMedia('(max-width: 768px)');
  const dispatch = useTypedDispatch();

  if (
    !mainMenuBanners ||
    !mainMenuBanners.data ||
    !mainMenuBanners.data.length
  ) {
    return null;
  }

  return (
    <Component onClick={() => handleMobileMenuClose(isMobile, dispatch)}>
      <Title to={url}>{label}</Title>

      <ImageWrapper>
        <Image
          loading="lazy"
          {...convertThumbnailToPictureProps(
            mainMenuBanners.data[0].image.default
          )}
        />
        <ImageLink
          to={mainMenuBanners.data[0].link}
          target={mainMenuBanners.data[0].openNewTab ? '_blank' : '_self'}
        />
      </ImageWrapper>
    </Component>
  );
}

export default Banner;

const Component = styled.div`
  margin-top: 16px;
`;

const Title = styled(Link)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${(props) => props.theme.black200};
`;

const ImageWrapper = styled.div`
  position: relative;
  max-width: 400px;
  height: 200px;
  margin-top: 16px;
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

const ImageLink = styled(Link)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;
