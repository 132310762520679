import React from 'react';
import styled from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

interface Props {
  isPurchase?: boolean;
}

function Pay({ isPurchase }: Props) {
  const logos = useSettingItem('FOOTER_LOGOS');
  const purchaseLogos = useSettingItem('FOOTER_CHECKOUT_PAYMENT_LOGOS');

  const items = isPurchase ? purchaseLogos : logos;

  if (!items || !items.length) {
    return null;
  }

  return (
    <Component>
      <List>
        {items.map((item, index) => {
          return (
            <Item key={index}>
              <Picture
                loading="lazy"
                src={item?.url}
                src2x={item?.url_2x}
                srcWebp={item?.url_webp}
                srcWebp2x={item?.url_webp_2x}
              />
            </Item>
          );
        })}
      </List>
    </Component>
  );
}

export default Pay;

const Component = styled.div``;

const List = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px -10px;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

const Item = styled.span`
  margin: 5px 10px;

  picture,
  img {
    height: 25px;

    svg {
      fill: ${colors.white};
      opacity: 0.4;
    }
  }
`;
