import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

function SignIn() {
  return (
    <Component>
      <Button href={'/personal/login'}>
        <Text>Войти</Text>
      </Button>
    </Component>
  );
}

export default SignIn;

const Component = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;

  margin-left: 10px;
`;

const Button = styled.a`
  display: inline-flex;
  vertical-align: top;
  padding: 20px 25px;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  cursor: pointer;
  transition: 0.15s;
  z-index: 2;

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  &:hover {
    background: ${(props) => props.theme.white};
    color: ${(props) => props.theme.main};
  }
`;

const Text = styled.span``;
