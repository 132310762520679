import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { StringField } from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import { selectMainMenuBanners } from '@/store/reducers/banners';

interface Props {
  label: StringField;
  link: StringField;
}

function Promotions({ label, link }: Props) {
  const mainMenuBanners = useTypedSelector(selectMainMenuBanners);

  if (
    !mainMenuBanners ||
    !mainMenuBanners.data ||
    !mainMenuBanners.data.length
  ) {
    return null;
  }

  return (
    <Component>
      {label ? (
        <Header>
          <Title>
            <TitleLink to={link}>{label}</TitleLink>
          </Title>
        </Header>
      ) : null}
      {mainMenuBanners.data && mainMenuBanners.data.length ? (
        <Banner>
          <BannerLink
            to={mainMenuBanners.data[0].link}
            target={mainMenuBanners.data[0].openNewTab ? '_blank' : '_self'}
          />
          <BannerImage
            loading="lazy"
            {...convertThumbnailToPictureProps(
              mainMenuBanners.data[0].image.default
            )}
          />
        </Banner>
      ) : null}
    </Component>
  );
}

export default Promotions;

const Component = styled.div`
  max-width: 400px;
`;

const Header = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
`;

const TitleLink = styled(Link)`
  color: ${(props) => props.theme.black200};
  border-bottom: 1px solid transparent;
  transition: color 0.15s, border 0.15s;

  &:hover {
    color: ${(props) => props.theme.main};
    border-bottom-color: ${(props) => props.theme.main};
  }
`;

const Banner = styled.div`
  position: relative;
  width: 400px;
`;

const BannerLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const BannerImage = styled(Picture)`
  padding-top: 55%;

  img,
  picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
