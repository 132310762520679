import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { useTypedDispatch } from '@/store/store';
import { setMobileMenuOpened } from '@/store/reducers/layout';
import { colors } from '@/constants/theme';

interface Props {
  cars: Array<{ label: string; icon: string; url: string }>;
}

function Catalog({ cars }: Props) {
  const dispatch = useTypedDispatch();

  return (
    <Component>
      {cars.map((car, index) => (
        <Item key={index} onClick={() => dispatch(setMobileMenuOpened(false))}>
          <Link to={car.url}>
            <Icon>
              <img loading="lazy" src={car.icon} alt={car.label} />
            </Icon>
            <Text>{car.label}</Text>
          </Link>
        </Item>
      ))}
    </Component>
  );
}

export default Catalog;

const Component = styled.ul`
  margin: 10px 0 30px 0;
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  a {
    display: inline-flex;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: ${(props) => props.theme.main};
    transition: color 0.15s;
  }
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  background: ${colors.white};
  border-radius: 10px;
  padding: 2px;
  margin-right: 8px;

  img {
    display: block;
    width: 32px;
    height: 32px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const Text = styled.span``;
