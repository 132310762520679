import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useIsomorphicLayoutEffect } from '@tager/web-core';

import Link from '@/components/Link';
import { useHover } from '@/hooks/useHover';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { colors } from '@/constants/theme';
import useSettingItem from '@/hooks/useSettingItem';
import { HeaderVariables } from '@/layout/components/Header/types';

import Promotions from './components/Promotions';
import OtherDropDown from './components/OtherDropDown';
import Cars from './components/Cars';

function Menu() {
  const headerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];

  const { isHovering, getHoverProps } = useHover({
    mouseLeaveDelay: 300,
  });

  const headerBrands = useSettingItem('HEADER_BRANDS');

  const menuList = headerMenu.map((item) => {
    return {
      label: item.label ?? '',
      link: item.link ?? '',
      children: item.children,
      hidden: false,
    };
  });

  const containerRef = useRef<HTMLElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);

  const [otherItems, setOtherItems] = useState<
    Array<{ label: string; link: string }>
  >([]);
  const [isOpacity, setIsOpacity] = useState<boolean>(true);

  const truncate = useCallback(() => {
    if (!containerRef.current || !listRef.current) {
      return;
    }

    const childNodes = Array.from(
      listRef.current.children
    ) as Array<HTMLElement>;

    for (let node of childNodes) {
      node.hidden = true;
    }

    if (childNodes.length === 0) {
      return;
    }

    for (let index = 0; index < childNodes.length; index++) {
      const item = childNodes[index];
      const nextItem = childNodes[index + 1];

      if (nextItem) {
        item.hidden = false;
        nextItem.hidden = false;
        const nextItemRect = nextItem.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        nextItem.hidden = true;

        if (Math.round(nextItemRect.right) >= Math.round(containerRect.right)) {
          item.hidden = true;
          if (index > 0) {
            childNodes[index - 1].hidden = false;
          }
          break;
        }
      }
    }

    const hiddenItems = childNodes.filter((h) => h.hidden).length - 1;

    if (childNodes.length > hiddenItems && hiddenItems) {
      childNodes[childNodes.length - 1].hidden = false;
    }

    let indexes: Array<number> = [];

    childNodes.forEach((item, index) => {
      if (item.hidden && menuList[index]) {
        indexes.push(index);
      }
    });

    setOtherItems(indexes.map((index) => menuList[index]));

    if (isOpacity) {
      setIsOpacity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpacity]);

  useIsomorphicLayoutEffect(() => {
    truncate();

    window.addEventListener('resize', truncate, false);
    return () => {
      window.removeEventListener('resize', truncate, false);
    };
  }, [truncate]);

  return (
    <Component ref={containerRef} isOpacity={isOpacity}>
      <NavList ref={listRef}>
        {menuList.map((menuItem, index) => {
          if (menuItem.hidden) {
            return null;
          }

          if (menuItem.children.length) {
            const brands = menuItem.children.filter(
              (item) => item.label.indexOf(HeaderVariables.Brands) !== -1
            );
            const promotions = menuItem.children.filter(
              (item) => item.label.indexOf(HeaderVariables.Banner) !== -1
            );
            const pagesMenu = menuItem.children.filter(
              (item) =>
                item.label.indexOf(HeaderVariables.Brands) === -1 &&
                item.label.indexOf(HeaderVariables.Banner) === -1
            );

            return (
              <NavItem key={index} {...getHoverProps()}>
                <NavLink to={menuItem.link} isOpen={isHovering}>
                  {menuItem.label}
                </NavLink>
                <DropdownMenu isOpen={isHovering}>
                  <DropdownMenuContainer>
                    <DropdownMenuTop>
                      {brands &&
                        brands.length > 0 &&
                        headerBrands &&
                        headerBrands.length > 0 && (
                          <Cars
                            cars={headerBrands.map((brand) => ({
                              label: brand.name ?? '',
                              link: brand.link ?? '',
                              icon: brand.image?.url ?? '',
                            }))}
                          />
                        )}

                      {promotions && promotions.length > 0 && (
                        <Promotions
                          label={promotions[0].label
                            .replace(HeaderVariables.Banner, '')
                            .trim()}
                          link={promotions[0].link}
                        />
                      )}
                    </DropdownMenuTop>

                    {pagesMenu && pagesMenu.length > 0 && (
                      <DropdownMenuBottom>
                        {pagesMenu.map((item) => (
                          <DropdownMenuBottomItem key={item.link}>
                            <DropdownMenuBottomLink to={item.link}>
                              {item.label}
                            </DropdownMenuBottomLink>
                          </DropdownMenuBottomItem>
                        ))}
                      </DropdownMenuBottom>
                    )}
                  </DropdownMenuContainer>
                </DropdownMenu>
              </NavItem>
            );
          }

          return (
            <NavItem key={menuItem.link}>
              <NavLink to={menuItem.link}>{menuItem.label}</NavLink>
            </NavItem>
          );
        })}

        <NavItem>
          <OtherDropDown items={otherItems} />
        </NavItem>
      </NavList>
    </Component>
  );
}

export default Menu;

const Component = styled.nav<{ isOpacity: boolean }>`
  display: flex;
  flex: 1 1 auto;
  align-self: stretch;
  align-items: stretch;
  opacity: ${({ isOpacity }) => (isOpacity ? 0 : 1)};
  overflow: ${({ isOpacity }) => (isOpacity ? 'hidden' : '')};
`;

const NavList = styled.ul`
  display: flex;
  flex: 0 0 auto;
  align-items: stretch;
  margin: 0 auto;
`;

const NavItem = styled.li`
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;

  &[hidden] {
    display: none;
  }
`;

const NavLink = styled(Link)<{ isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 15px;
  border: none;
  color: ${colors.white};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.15s;
  white-space: nowrap;
  flex-wrap: nowrap;
  z-index: 91;

  &:hover {
    background: ${(props) => props.theme.white};
    color: ${(props) => props.theme.main};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${(props) => props.theme.white};
      color: ${(props) => props.theme.main};
    `};

  @media (min-width: 1200px) {
    padding: 21px 25px;
  }
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  display: none;

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 201;

  background: ${(props) => props.theme.white};
  padding: 40px 0 30px;
  box-shadow: ${(props) => props.theme.blackShadow300};

  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.black200};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`;

const DropdownMenuContainer = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
`;

const DropdownMenuTop = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 30px;
`;

const DropdownMenuBottom = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #dee4ec;
`;

const DropdownMenuBottomItem = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DropdownMenuBottomLink = styled(Link)`
  color: ${(props) => props.theme.main};
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom-color: ${(props) => props.theme.main};
  }
`;
