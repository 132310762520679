import React from 'react';
import styled from 'styled-components';

import { componentColors } from '@/layout/components/DealerWebFooter/constants';
import Link from '@/components/Link';
import AppstoreIcon from '@/assets/images/appstore.png';
import GooglePlayIcon from '@/assets/images/google-play.png';

import { AppsProps } from './types';

function Apps({ appstore, googlePlay }: AppsProps) {
  if (!appstore && !googlePlay) {
    return null;
  }

  return (
    <Component>
      <Title>Мобильное приложение</Title>

      <List>
        {appstore && (
          <AppLink to={appstore} target="_blank">
            <img loading="lazy" src={AppstoreIcon} alt="" />
          </AppLink>
        )}

        {googlePlay && (
          <AppLink to={googlePlay} target="_blank">
            <img loading="lazy" src={GooglePlayIcon} alt="" />
          </AppLink>
        )}
      </List>
    </Component>
  );
}

export default Apps;

const Component = styled.div``;

const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: ${componentColors.footerNavHeader};
`;

const List = styled.div`
  display: flex;
  margin-top: 15px;
`;

const AppLink = styled(Link)`
  &:not(:first-child) {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.6;
  }
`;
