import { useRef, useState } from 'react';

interface Props {
  mouseLeaveDelay?: number;
}

export function useHover({ mouseLeaveDelay = 0 }: Props): {
  isHovering: boolean;
  getHoverProps: () => { onMouseEnter: () => void; onMouseLeave: () => void };
} {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const mouseLeaveTimerId = useRef<NodeJS.Timeout | null>(null);

  function onMouseEnter(): void {
    if (mouseLeaveTimerId.current) {
      clearTimeout(mouseLeaveTimerId.current);
    }
    setIsHovering(true);
  }

  function onMouseLeave(): void {
    mouseLeaveTimerId.current = setTimeout(() => {
      setIsHovering(false);
    }, mouseLeaveDelay);
  }

  function getHoverProps(): {
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  } {
    return {
      onMouseEnter,
      onMouseLeave,
    };
  }

  return { isHovering, getHoverProps };
}
