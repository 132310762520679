import React from 'react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import Link from '@/components/Link';
import { useTypedDispatch } from '@/store/store';
import { handleMobileMenuClose } from '@/utils/common';

import { MenuProps } from './Menu.types';

export const Menu: React.VFC<MenuProps> = ({ items }) => {
  const isMobile = useMedia('(max-width: 767.9px)');
  const dispatch = useTypedDispatch();

  return (
    <Component>
      <List>
        {items.map(({ label, link }, index) => {
          return (
            <React.Fragment key={index}>
              <Item onClick={() => handleMobileMenuClose(isMobile, dispatch)}>
                <Link to={link}>{label}</Link>
              </Item>
            </React.Fragment>
          );
        })}
      </List>
    </Component>
  );
};

const Component = styled.div`
  margin: 20px 0 20px 0;
`;

const List = styled.ul``;

const Item = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  a,
  button {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    text-transform: uppercase;

    color: ${(props) => props.theme.black200};
  }
`;
