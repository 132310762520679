import React from 'react';
import styled, { css } from 'styled-components';
import { Router } from 'next/router';

import { useMedia } from '@tager/web-core';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { getIsUserAuthorized } from '@/store/reducers/auth';
import {
  selectMobileMenuOpened,
  setMobileMenuOpened,
} from '@/store/reducers/layout';
import { media } from '@/utils/mixin';

import Logo from './components/Logo';
import Location from './components/Location';
import MobilePhone from './components/MobilePhone';
import MobileMenu from './components/MobileMenu';
import Menu from './components/Menu';
import Phone from './components/Phone';
import { User } from './components/User';
import { MobileUser } from './components/User';
import SignIn from './components/SignIn';

interface Props {
  className?: string;
}

function Header({ className }: Props) {
  const dispatch = useTypedDispatch();
  const isUserAuthorized = useTypedSelector(getIsUserAuthorized);
  const isMobileMenuOpened = useTypedSelector(selectMobileMenuOpened);
  const isMobile = useMedia('(max-width: 1259px)');

  Router.events.on('routeChangeComplete', () => {
    if (isMobile && isMobileMenuOpened) {
      dispatch(setMobileMenuOpened(false));
    }
  });

  return (
    <Component className={className}>
      <Container>
        {isMobile ? (
          <>
            <Logo />
            <Right>
              <Location isMobile={isMobile} />
              <MobileUser />
              <MobilePhone />
              <MobileMenu />
            </Right>
          </>
        ) : (
          <>
            <Logo />
            <Menu />
            <Location />
            <Phone />
            {isUserAuthorized ? <User /> : <SignIn />}
          </>
        )}
      </Container>
    </Component>
  );
}

export default Header;

export const Component = styled.header`
  z-index: 9998;
  position: relative;
  height: 80px;
  border-bottom: 1px solid ${(props) => props.theme.blue_1};
  background: ${(props) => props.theme.blue};

  ${media.tablet(css`
    position: sticky;
    top: 0;
  `)}

  ${media.mobile(css`
    height: 60px;
  `)}
`;

const Container = styled.div`
  padding: 0 40px;
  height: 80px;
  display: flex;
  align-items: center;

  ${media.tablet(css`
    justify-content: space-between;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    height: 60px;
  `)}
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;
