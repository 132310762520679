import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';

import { cookie, useIsomorphicLayoutEffect } from '@tager/web-core';

import { OptionType } from '@/typings/common';
import NavigationIcon from '@/assets/svg/navigation.svg';
import { media } from '@/utils/mixin';

import { locationData } from '../../constants';

function LocationMobile() {
  const [selectedCity, setSelectedCity] = useState<OptionType>(locationData[4]);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
  } = useSelect({
    selectedItem: selectedCity,
    items: locationData,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setSelectedCity(selectedItem);
        cookie.set('selectedCity', JSON.stringify(selectedItem));
      }
    },
  });

  useIsomorphicLayoutEffect(() => {
    const selectedLocationFromCookie = JSON.parse(
      String(cookie.get('selectedCity'))
    ) as unknown as OptionType;

    if (selectedLocationFromCookie) {
      setSelectedCity(selectedLocationFromCookie);
    }
  }, []);

  return (
    <Component>
      <Select type="button" {...getToggleButtonProps()}>
        <NavigationIcon />
      </Select>

      <Content isOpen={isOpen} {...getMenuProps()}>
        {locationData.map((item, index) => {
          const isActive = item.value === selectedItem?.value;

          return (
            <ListItem
              key={index}
              isActive={isActive}
              isOpen={isOpen}
              {...getItemProps({
                item: item,
                index,
              })}
            >
              {item.label}
            </ListItem>
          );
        })}
      </Content>
    </Component>
  );
}

export default LocationMobile;

const Component = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Select = styled.button`
  width: 40px;
  height: 40px;

  ${media.mobileSmall(css`
    width: 25px;
    height: 25px;
  `)}

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Content = styled.div<{ isOpen: boolean }>`
  z-index: 501;
  pointer-events: none;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  max-height: 0;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.blackShadow100};
  opacity: 0;
  transition: all 0.3s ease-in-out;

  ${media.mobileSmall(css`
    top: 30px;
  `)}

  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: auto;
      opacity: 1;
      max-height: 200px;
      transition: all 0.3s ease-in-out;
    `}
`;

const ListItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 7px 10px;
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.black200};

  &:hover {
    color: ${(props) => props.theme.main};
    background: ${(props) => props.theme.white_1};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 700;
    `}
`;
