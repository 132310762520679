import React from 'react';
import styled, { css } from 'styled-components';

import { isNotNullish, useMedia } from '@tager/web-core';

import AccountIcon from '@/assets/svg/account.svg';
import ChevronDownIcon from '@/assets/svg/chevron-down.svg';
import { useTypedSelector } from '@/store/store';
import { getProfile } from '@/store/reducers/auth';
import { useHover } from '@/hooks/useHover';
import NoCars from '@/layout/components/Header/components/User/components/NoCars';
import { colors } from '@/constants/theme';

import Promo from './components/Promo';
import Actions from './components/Actions';
import Slider from './components/Slider';
import Menu from './components/Menu';
import Logout from './components/Logout';

function User() {
  const { isHovering, getHoverProps } = useHover({
    mouseLeaveDelay: 300,
  });
  const isUserIcon = useMedia('(max-width: 1200px)');

  const profile = useTypedSelector(getProfile);

  const slides = (profile?.cars ?? []).filter((car) => !car.archive) ?? [];
  const isUserCars = slides.length > 0;

  return (
    <Dropdown {...getHoverProps()}>
      <DropdownToggle type="button" isOpen={isHovering}>
        {isUserIcon ? (
          <UserIcon>
            <AccountIcon />
          </UserIcon>
        ) : (
          <DropdownToggleText>{profile?.firstName}</DropdownToggleText>
        )}
        <DropdownToggleIcon>
          <ChevronDownIcon />
        </DropdownToggleIcon>
      </DropdownToggle>

      <DropdownMenu isOpen={isHovering}>
        <Container>
          <Content>
            {isUserCars ? (
              <Slider slides={slides} />
            ) : profile?.availability?.cars ? (
              <NoCars infoType="archived" />
            ) : (
              <NoCars infoType="noCars" />
            )}

            <Menu
              items={[
                {
                  label: 'Личные данные',
                  url: '/personal',
                },
              ]}
            />

            <Menu
              items={[
                profile?.hasCars
                  ? {
                      label: 'Мои автомобили',
                      url: '/personal/cars',
                    }
                  : undefined,
                profile?.availability?.purchasesCars ||
                profile?.availability?.purchasesOther ||
                profile?.availability?.purchasesInsurances ||
                profile?.availability?.purchasesServices
                  ? {
                      label: 'Мои покупки',
                      url: '/personal/purchases/',
                    }
                  : undefined,
                profile?.availability?.requests
                  ? {
                      label: 'Мои заявки',
                      url: '/personal/requests/',
                    }
                  : undefined,
                profile?.availability?.bonuses
                  ? {
                      label: 'Мои бонусы',
                      url: '/personal/bonuses/',
                    }
                  : undefined,
              ].filter(isNotNullish)}
            />

            <Logout />
            <Actions />
            <Promo />
          </Content>
        </Container>
      </DropdownMenu>
    </Dropdown>
  );
}

export default User;

const Dropdown = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;

  margin-left: 10px;
`;

const DropdownToggleText = styled.span``;

const DropdownToggleIcon = styled.span`
  margin-left: 14px;
  transition: transform 0.15s;
`;

const DropdownToggle = styled.button<{ isOpen: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px 25px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${colors.white};
  transition: 0.15s;
  z-index: 11;

  ${({ isOpen }) =>
    isOpen &&
    css`
      color: ${(props) => props.theme.main};
      background: ${(props) => props.theme.white};

      ${DropdownToggleIcon} {
        transform: rotate(180deg);

        svg {
          path {
            stroke: ${(props) => props.theme.main};
          }
        }
      }
    `}
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  display: none;

  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  width: 100%;
  z-index: 201;

  padding: 30px;

  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.blackShadow300};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `}
`;

const Container = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const UserIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }
`;
