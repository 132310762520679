import React from 'react';
import styled from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import { colors } from '@/constants/theme';

import { FooterContainer } from '../FooterContainer';

function Bottom() {
  const copyright = useSettingItem('FOOTER_COPYRIGHT');

  return (
    <Component id="bottom-footer">
      <FooterContainer width={1280}>
        <Grid>
          <Copyright>{copyright}</Copyright>
        </Grid>
      </FooterContainer>
    </Component>
  );
}

export default Bottom;

const Component = styled.div`
  padding: 20px 0 30px;
  background: ${(props) => props.theme.white};

  @media (min-width: 1200px) {
    padding: 30px 0;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    align-items: flex-start;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Copyright = styled.div`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.blue600};

  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
