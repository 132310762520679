import React from 'react';
import styled from 'styled-components';

import { usePageType } from '@/hooks/usePageType';

import Bottom from './components/Bottom';
import Main from './components/Main';

export function Footer() {
  const { template } = usePageType();
  const isServicesTemplate = template === 'services';

  return (
    <FooterContainer id="footer">
      <Main menuAlias="footer" />
      {!isServicesTemplate && <Bottom />}
    </FooterContainer>
  );
}

const FooterContainer = styled.footer``;
