import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import ChevronRightIcon from '@/assets/svg/chevron-right-24dp.svg';
import Link from '@/components/Link';
import { setMobileMenuOpened } from '@/store/reducers/layout';
import { useTypedDispatch } from '@/store/store';
import { colors } from '@/constants/theme';

import { MenuItemModel } from './MobileMenu';

interface Props extends MenuItemModel {
  onMenuItemClick?: () => void;
}

function MenuItem({ link, label, subMenu, onMenuItemClick }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useTypedDispatch();

  function handleSubMenuToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <Component onClick={onMenuItemClick}>
      {subMenu ? (
        <>
          <MenuLinkWrapper>
            <div
              onClick={() => dispatch(setMobileMenuOpened(false))}
              style={{ width: '100%' }}
            >
              <MenuLink to={link}>
                <MenuLabel>{label}</MenuLabel>
              </MenuLink>
            </div>
            <SubMenuToggle
              onClick={(event) => {
                event.stopPropagation();
                handleSubMenuToggle();
              }}
            >
              <StyledChevronRightIcon />
            </SubMenuToggle>
          </MenuLinkWrapper>
          <SubMenu isOpen={isOpen}>
            <GoBackButton
              onClick={(event) => {
                event.stopPropagation();
                handleSubMenuToggle();
              }}
            >
              <GoBackButtonIcon>
                <StyledChevronRightIcon />
              </GoBackButtonIcon>
              <GoBackButtonLabel>{label}</GoBackButtonLabel>
            </GoBackButton>
            {subMenu}
          </SubMenu>
        </>
      ) : (
        <div onClick={() => dispatch(setMobileMenuOpened(false))}>
          <MenuLink to={link}>
            <MenuLabel>{label}</MenuLabel>
          </MenuLink>
        </div>
      )}
    </Component>
  );
}

export default MenuItem;

const Component = styled.li``;

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray200};
`;

const MenuLabel = styled.span`
  display: block;
  cursor: pointer;

  flex: 1 1 auto;

  padding: 15px 20px;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;

  color: ${(props) => props.theme.black200};

  transition: 0.15s;
`;

const MenuLinkWrapper = styled.div`
  display: flex;
  align-items: center;

  > a {
    flex: 1 0 0;
    align-self: stretch;
    align-items: stretch;

    border-bottom: none;
  }
`;

const SubMenuToggle = styled.button`
  position: relative;

  display: flex;
  align-self: stretch;
  align-items: stretch;
  justify-content: center;

  width: 100%;
  max-width: 80px;

  padding: 10px 20px;
  border-bottom: 1px solid ${colors.gray200};

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    bottom: 5px;
    border-left: 1px solid ${colors.gray200};
  }

  svg {
    margin: auto;
  }
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  fill: ${(props) => props.theme.main};
`;

const SubMenu = styled.div<{ isOpen: boolean }>`
  display: none;

  position: fixed;
  top: 60px;
  left: 0;
  z-index: 101;

  overflow-x: hidden;
  overflow-y: scroll;

  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 60px);

  background: ${(props) => props.theme.white};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`;

const GoBackButton = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 15px 20px 15px 45px;

  text-align: start;
`;

const GoBackButtonIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 8.295px;
  transform: translateY(-50%) rotate(180deg);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const GoBackButtonLabel = styled(MenuLabel)`
  padding: 0;
`;
