import React from 'react';
import styled from 'styled-components';

import appstoreIcon from '@/assets/images/apps/appstore.svg?url';
import googlePlayIcon from '@/assets/images/apps/gplay.svg?url';
import useSettingItem from '@/hooks/useSettingItem';

function Apps() {
  const appStoreUrl = useSettingItem('MOBILE_APPSTORE_URL');
  const googlePlayUrl = useSettingItem('MOBILE_GOOGLE_PLAY_URL');

  return (
    <Component>
      {appStoreUrl ? (
        <Link href={appStoreUrl} target="_blank">
          <img loading="lazy" src={appstoreIcon} alt="" />
        </Link>
      ) : null}
      {googlePlayUrl ? (
        <Link href={googlePlayUrl} target="_blank">
          <img loading="lazy" src={googlePlayIcon} alt="" />
        </Link>
      ) : null}
    </Component>
  );
}

export default Apps;

const Component = styled.div``;

const Link = styled.a`
  display: block;
  width: 132px;
  height: 40px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
