import React from 'react';
import { useRouter } from 'next/router';

import { Nullable, useMedia } from '@tager/web-core';

import { usePageType } from '@/hooks/usePageType';

export const useDealerWebLinkClick = () => {
  const router = useRouter();
  const { template } = usePageType();
  const isMobile = useMedia('(max-width: 1259px)');

  const isDealerTemplate =
    template === 'b2b-home' ||
    template === 'hisun-home' ||
    template === 'jac-home' ||
    template === 'jetour-home';

  function handleClickLink(
    link: Nullable<string>,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    if (!link) {
      return;
    }

    if (link.startsWith('#')) {
      event.preventDefault();

      if (isDealerTemplate) {
        if (router.asPath !== '/') {
          router.replace('/');
        }

        const element = document.getElementById(link.substring(1));

        if (!element) {
          return;
        }

        window.scrollTo({
          top:
            element.getBoundingClientRect().top +
            window.scrollY -
            (!isMobile ? 30 : 80),
          behavior: 'smooth',
        });
      } else {
        router.push(`/${link}`);
      }
    }
  }

  return { handleClickLink };
};
