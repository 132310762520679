import React from 'react';
import styled from 'styled-components';

import { MenuItemType } from '@tager/web-modules';

import { HeaderVariables } from '@/layout/components/Header/types';
import useSettingItem from '@/hooks/useSettingItem';
import { colors } from '@/constants/theme';

import Catalog from './components/Catalog';
import Banner from './components/Banner';
import { Menu } from './components/Menu';

interface Props {
  menuItems: Array<MenuItemType>;
}

function NewCars({ menuItems }: Props) {
  const headerBrands = useSettingItem('HEADER_BRANDS');

  const brands = menuItems.filter(
    (item) => item.label.indexOf(HeaderVariables.Brands) !== -1
  );
  const promotions = menuItems.filter(
    (item) => item.label.indexOf(HeaderVariables.Banner) !== -1
  );
  const pagesMenu = menuItems.filter(
    (item) =>
      item.label.indexOf(HeaderVariables.Brands) === -1 &&
      item.label.indexOf(HeaderVariables.Banner) === -1
  );

  return (
    <Component>
      {brands && brands.length > 0 && headerBrands && headerBrands.length > 0 && (
        <Catalog
          cars={headerBrands.map((brand) => ({
            label: brand.name ?? '',
            url: brand.link ?? '',
            icon: brand.image?.url ?? '',
          }))}
        />
      )}

      <Divider />

      {pagesMenu && pagesMenu.length > 0 && <Menu items={pagesMenu} />}

      {promotions && promotions.length > 0 && (
        <Banner
          label={promotions[0].label.replace(HeaderVariables.Banner, '').trim()}
          url={promotions[0].link ?? ''}
        />
      )}
    </Component>
  );
}

export default NewCars;

const Component = styled.div`
  padding: 0 20px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${colors.gray400};
`;
