import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { componentColors } from '@/layout/components/DealerWebHeader/constants';
import ArrowIcon from '@/assets/svg/chevron-down.svg';
import Models from '@/layout/components/DealerWebHeader/components/Models';

import { ModelsDropdownProps } from './types';

function ModelsDropdown({ label, link, models, onClick }: ModelsDropdownProps) {
  const [isOpened, setOpened] = useState(false);

  return (
    <Component>
      <Header>
        <StyledLink
          to={link}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
            onClick?.(link, event)
          }
        >
          {label}
        </StyledLink>
        <Button isOpened={isOpened} onClick={() => setOpened(!isOpened)}>
          <ArrowIcon />
        </Button>
      </Header>

      <ModelsWrapper isOpened={isOpened}>
        <Models models={models} />
      </ModelsWrapper>
    </Component>
  );
}

export default ModelsDropdown;

const Component = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 130%;
  color: ${componentColors.headerNav};
`;

const Button = styled.button<{ isOpened: boolean }>`
  padding-left: 20px;

  svg {
    transform: rotate(-90deg);

    path {
      stroke: ${componentColors.headerNav};
    }
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      svg {
        transform: rotate(0);
      }
    `};
`;

const ModelsWrapper = styled.div<{ isOpened: boolean }>`
  display: none;

  ${({ isOpened }) =>
    isOpened &&
    css`
      display: inherit;
      margin-top: 20px;
    `}
`;
