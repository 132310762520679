import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import useSettingItem from '@/hooks/useSettingItem';

import Bottom from './components/Bottom';
import Main from './components/Main';

type Props = {
  subdomain: Nullable<string>;
};

export function FooterPurchase({ subdomain }: Props) {
  const disclaimerBySubdomains = useSettingItem(
    'FOOTER_CHECKOUT_DISCLAIMER_SUBDOMAINS'
  );
  const title = useSettingItem('FOOTER_CHECKOUT_TITLE');

  let footerText = useSettingItem('FOOTER_CHECKOUT_DISCLAIMER');

  if (subdomain) {
    const subdomainData = disclaimerBySubdomains.find(
      (item) => item.subdomain.toLowerCase() === subdomain
    );

    if (subdomainData) {
      footerText = subdomainData.text;
    }
  }

  return (
    <FooterContainer id="footer">
      <Main
        title={title ?? ''}
        text={footerText ?? ''}
        menuAlias="footer_purchase"
        isPurchase
      />
      <Bottom />
    </FooterContainer>
  );
}

const FooterContainer = styled.footer``;
