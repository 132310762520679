import React from 'react';
import styled from 'styled-components';

import { useModal } from '@tager/web-components';
import { useMedia } from '@tager/web-core';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import useSettingItem from '@/hooks/useSettingItem';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import Link from '@/components/Link';
import { setMobileMenuOpened } from '@/store/reducers/layout';
import {
  getPhoneLink,
  handleMobileMenuClose,
  handleSubmitCallbackForm,
} from '@/utils/common';
import CallbackForm from '@/components/modals/CallbackForm';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { googleEvent } from '@/utils/events';
import { usePhoneNumber } from '@/hooks/usePhoneNumber';
import { HeaderVariables } from '@/layout/components/Header/types';

interface InfoProps {
  onInfoItemClick?: () => void;
}

function Info({ onInfoItemClick }: InfoProps) {
  const menuItems =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, 'header_mobile')
    ) ?? [];

  const openModal = useModal();
  const isMobile = useMedia('(max-width: 768px)');
  const phoneCallbackGoogleEvent = useSettingItem(
    'PHONE_CALLBACK_GOOGLE_EVENT'
  );
  const phoneClickGoogleEvent = useSettingItem('PHONE_CLICK_GOOGLE_EVENT');

  const onPhoneClick = () => {
    if (phoneClickGoogleEvent) {
      googleEvent(phoneClickGoogleEvent);
    }
  };

  function handleCallClick(event: React.MouseEvent) {
    event.preventDefault();

    openModal(
      CallbackForm,
      {
        request: (values) =>
          handleSubmitCallbackForm(values).then(() => {
            if (phoneCallbackGoogleEvent) {
              googleEvent(phoneCallbackGoogleEvent);
            }
          }),
        title: 'Заказать обратный звонок',
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  }

  const email = useSettingItem('EMAIL');

  const phoneNumber = usePhoneNumber();
  const phoneNumberLink = getPhoneLink(phoneNumber);

  const dispatch = useTypedDispatch();

  return (
    <Component>
      <Menu>
        {menuItems.map((menuItem, index) => (
          <React.Fragment key={index}>
            <MenuLabel
              onClick={() => {
                onInfoItemClick?.();
                dispatch(setMobileMenuOpened(false));
              }}
            >
              <MenuLabelLink to={menuItem.link}>{menuItem.label}</MenuLabelLink>
            </MenuLabel>
            <MenuList>
              {menuItem.children.map((menuChildItem, index) => {
                let label = menuChildItem.label;
                let link = menuChildItem.link;

                if (menuChildItem.label === HeaderVariables.Phone) {
                  label = phoneNumber ?? '';
                  link = phoneNumberLink;
                } else if (menuChildItem.label === HeaderVariables.Email) {
                  label = email ?? '';
                  link = 'mailto:' + email;
                }

                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      onInfoItemClick?.();
                      handleMobileMenuClose(isMobile, dispatch);
                    }}
                  >
                    {link === HeaderVariables.Call ? (
                      <button
                        type="button"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCallClick(event);
                        }}
                      >
                        {label}
                      </button>
                    ) : (
                      <Link
                        to={link}
                        target={menuChildItem.isNewTab ? '_blank' : '_self'}
                        onClick={() =>
                          link && link.startsWith('tel:+') && onPhoneClick()
                        }
                      >
                        {label}
                      </Link>
                    )}
                  </MenuItem>
                );
              })}
            </MenuList>
          </React.Fragment>
        ))}
      </Menu>
    </Component>
  );
}

export default Info;

const Component = styled.div`
  padding: 25px 20px;
`;

const Menu = styled.div``;

const MenuLabel = styled.h3`
  margin-bottom: 16px;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;

  text-transform: uppercase;
`;

const MenuLabelLink = styled(Link)`
  color: ${(props) => props.theme.black200};
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  button,
  a {
    color: ${(props) => props.theme.main};
    transition: color 0.15s;
  }
`;
