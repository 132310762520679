import { useRouter } from 'next/router';

import useSettingItem from '@/hooks/useSettingItem';
import {
  getServiceSingleLanding,
  getServiceBrandLanding,
  getServiceBrandServiceLanding,
} from '@/store/reducers/service';
import useLayoutPage from '@/hooks/useLayoutPage';
import { useTypedSelector } from '@/store/store';
import { usePageType } from '@/hooks/usePageType';

export function usePhoneNumber() {
  const currentPage = useLayoutPage();
  const routePath = useRouter().asPath;
  const { pageType, template } = usePageType();

  const phoneCommon = useSettingItem('PHONE_COMMON');
  const phoneAmp = useSettingItem('PHONE_AMP');
  const templatePhone = currentPage?.templateFields?.phone || phoneCommon;

  const serviceSingleData = useTypedSelector(getServiceSingleLanding);
  const serviceBrandLandingData = useTypedSelector(getServiceBrandLanding);
  const serviceBrandServiceLandingData = useTypedSelector(
    getServiceBrandServiceLanding
  );

  switch (template) {
    case 'subscription':
    case 'atlantm30':
    case 'services':
    case 'special-offer':
    case 'value-car':
    case 'help-on-the-road':
    case 'b2b-home':
    case 'hisun-home':
    case 'jac-home':
    case 'jetour-home':
    case 'car-model-landing':
    case 'car-model-compare-landing':
    case 'electrocars-landing':
    case 'top-level-funnel-landing':
    case 'service-main':
      return templatePhone;
  }

  // Service - Single Service
  if (pageType === 'SERVICE_SINGLE_PAGE' && serviceSingleData) {
    return serviceSingleData?.phone || phoneCommon;
  }

  // Service - Single Brand
  if (pageType === 'SERVICE_BRAND_PAGE' && serviceBrandLandingData) {
    return serviceBrandLandingData?.phone || phoneCommon;
  }

  // Service - Single Service Brand
  if (
    pageType === 'SERVICE_BRAND_SERVICE_PAGE' &&
    serviceBrandServiceLandingData
  ) {
    return serviceBrandServiceLandingData?.phone || phoneCommon;
  }

  switch (true) {
    case routePath.startsWith('/cars/amp'):
      return phoneAmp;
    default:
      return phoneCommon;
  }
}
