import React from 'react';
import styled from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import FacebookIcon from '@/assets/images/social/facebook.svg';
import YoutubeIcon from '@/assets/images/social/youtube.svg';
import OdnoklassnikiIcon from '@/assets/images/social/odnoklassniki.svg';
import TwitterIcon from '@/assets/images/social/twitter.svg';
import InstagramIcon from '@/assets/images/social/instagram.svg';
import VkIcon from '@/assets/images/social/vk.svg';

function Socials() {
  const facebookUrl = useSettingItem('SOCIAL_FACEBOOK');
  const youtubeUrl = useSettingItem('SOCIAL_YOUTUBE');
  const odnoklassnikiUrl = useSettingItem('SOCIAL_ODNOKLASSNIKI');
  const twitterUrl = useSettingItem('SOCIAL_TWITTER');
  const instagramUrl = useSettingItem('SOCIAL_INSTAGRAM');
  const vkontakteUrl = useSettingItem('SOCIAL_VKONTAKTE');

  return (
    <Component>
      <List>
        {facebookUrl ? (
          <Item>
            <Link href={facebookUrl} target="_blank" title="Facebook">
              <FacebookIcon />
            </Link>
          </Item>
        ) : null}

        {youtubeUrl ? (
          <Item>
            <Link href={youtubeUrl} target="_blank" title="Youtube">
              <YoutubeIcon />
            </Link>
          </Item>
        ) : null}

        {odnoklassnikiUrl ? (
          <Item>
            <Link href={odnoklassnikiUrl} target="_blank" title="Одноклассники">
              <OdnoklassnikiIcon />
            </Link>
          </Item>
        ) : null}

        {twitterUrl ? (
          <Item>
            <Link href={twitterUrl} target="_blank" title="Twitter">
              <TwitterIcon />
            </Link>
          </Item>
        ) : null}

        {instagramUrl ? (
          <Item>
            <Link href={instagramUrl} target="_blank" title="Instagram">
              <InstagramIcon />
            </Link>
          </Item>
        ) : null}

        {vkontakteUrl ? (
          <Item>
            <Link href={vkontakteUrl} target="_blank" title="Vkontakte">
              <VkIcon />
            </Link>
          </Item>
        ) : null}
      </List>
    </Component>
  );
}

export default Socials;

const Component = styled.div`
  margin: 35px 0;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

const Link = styled.a`
  display: inline-block;

  &:hover {
    opacity: 0.6;
  }
`;
