import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import OpenMenu from '@/assets/svg/dealer-web/burger-button.svg';
import CloseMenu from '@/assets/svg/dealer-web/close-menu.svg';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import {
  componentColors,
  SubmenuTypes,
} from '@/layout/components/DealerWebHeader/constants';
import { media } from '@/utils/mixin';
import { useDealerWebLinkClick } from '@/layout/hooks';
import Link from '@/components/Link';
import Personal from '@/layout/components/DealerWebHeader/components/Personal';
import useSettingItem from '@/hooks/useSettingItem';

import ModelsDropdown from './components/ModelsDropdown';

function MobileMenuList() {
  const headerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];
  const dealerWebModels = useSettingItem('HEADER_DEALER_WEB_MODELS');
  const { handleClickLink } = useDealerWebLinkClick();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  function handleClickMenuItem(
    link: Nullable<string>,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    handleClickLink(link, event);
    setIsOpen(false);
  }

  return (
    <Component>
      <BurgerButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <CloseMenu /> : <OpenMenu />}
      </BurgerButton>

      <Container isOpen={isOpen}>
        <MenuList>
          {headerMenu.map((item, index) => {
            const isModelsDropdown =
              item.label.indexOf(SubmenuTypes.Models) !== -1;

            if (
              isModelsDropdown &&
              dealerWebModels &&
              dealerWebModels.length > 0
            ) {
              return (
                <ModelsDropdownWrapper key={index}>
                  <ModelsDropdown
                    label={item.label.replace(SubmenuTypes.Models, '').trim()}
                    link={item.link ?? ''}
                    models={dealerWebModels.map(
                      ({ image, name, description, price, link }) => ({
                        image,
                        name: name ?? '',
                        description: description ?? '',
                        price: price ?? '',
                        link: link ?? '',
                        onClick: handleClickMenuItem,
                      })
                    )}
                    onClick={handleClickMenuItem}
                  />
                </ModelsDropdownWrapper>
              );
            }

            return (
              <MenuItem
                key={index}
                to={item.link ?? ''}
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => handleClickMenuItem(item.link, event)}
              >
                {item.label ?? ''}
              </MenuItem>
            );
          })}
        </MenuList>

        <PersonalWrapper>
          <Personal />
        </PersonalWrapper>
      </Container>
    </Component>
  );
}

export default MobileMenuList;

const Component = styled.div`
  margin-left: 20px;
`;

const BurgerButton = styled.button<{ isOpen: boolean }>`
  margin-top: 3px;

  svg {
    path {
      stroke: ${componentColors.headerBurger};
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        path {
          stroke: ${componentColors.headerBurgerClose};
        }
      }
    `}
`;

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 11;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  overflow: auto;
  background: ${componentColors.headerBackground};
  transform: translateX(100%);
  transition: 0.3s;

  ${media.mobile(css`
    top: 60px;
  `)}

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0);
    `}
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled(Link)`
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 30px;
  color: ${componentColors.headerNav};
`;

const ModelsDropdownWrapper = styled.div`
  margin-bottom: 30px;
`;

const PersonalWrapper = styled.div`
  padding-top: 20px;
  border-top: 1px solid #b6bcc2;
`;
