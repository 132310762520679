import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';

import { componentColors } from './constants';
import Logos from './components/Logos';
import MenuList from './components/MenuList';
import MobileMenuList from './components/MobileMenuList';
import Personal from './components/Personal';

export function B2bDealerWebHeader() {
  const isMobile = useMedia('(max-width: 1259px)');

  return (
    <Component>
      <Container>
        {isMobile ? (
          <>
            <Logos />
            <MobileMenuList />
          </>
        ) : (
          <>
            <Logos />
            <MenuList />
            <Personal />
          </>
        )}
      </Container>
    </Component>
  );
}

export function HisunDealerWebHeader() {
  const isMobile = useMedia('(max-width: 1259px)');

  return (
    <Component>
      <Container>
        {isMobile ? (
          <>
            <Logos />
            <MobileMenuList />
          </>
        ) : (
          <>
            <Logos />
            <MenuList />
            <Personal />
          </>
        )}
      </Container>
    </Component>
  );
}

export function JacDealerWebHeader() {
  const isMobile = useMedia('(max-width: 1259px)');

  return (
    <Component>
      <Container>
        {isMobile ? (
          <>
            <Logos />
            <MobileMenuList />
          </>
        ) : (
          <>
            <Logos />
            <MenuList />
            <Personal />
          </>
        )}
      </Container>
    </Component>
  );
}

export function JetourDealerWebHeader() {
  const isMobile = useMedia('(max-width: 1259px)');

  return (
    <Component>
      <Container>
        {isMobile ? (
          <>
            <Logos />
            <MobileMenuList />
          </>
        ) : (
          <>
            <Logos />
            <MenuList />
            <Personal />
          </>
        )}
      </Container>
    </Component>
  );
}

export const Component = styled.header`
  z-index: 9998;
  position: relative;
  display: flex;
  height: 80px;
  background: ${componentColors.headerBackground};
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.tablet(css`
    position: sticky;
    top: 0;
  `)}

  ${media.mobile(css`
    height: 60px;
  `)}
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 1920px;
  padding: 0 40px;
  margin: 0 auto;
  align-items: center;

  ${media.tablet(css`
    padding: 0 20px;
    justify-content: space-between;
  `)}
`;
