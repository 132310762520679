import { getTheme } from '@/constants/theme';

const colorsB2b = {
  headerBackground: '#fff',
  headerNav: '#182028',
  headerPhone: '#6C7075',
  headerPhoneHover: '#182028',
  headerPhoneButton: '#182028',
  headerPhoneButtonHover: '#0066b3',
  headerBurger: '#182028',
  headerBurgerClose: '#6c7075',
  headerModelCardPrimary: '#182028',
  headerModelCardSecondary: '#6c7075',
} as const;

const colorsHisun = {
  headerBackground: '#333',
  headerNav: '#fff',
  headerPhone: '#fff',
  headerPhoneHover: 'rgba(255, 255, 255, 0.7)',
  headerPhoneButton: '#fff',
  headerPhoneButtonHover: 'rgba(255, 255, 255, 0.7)',
  headerBurger: '#fff',
  headerBurgerClose: '#858585',
  headerModelCardPrimary: '#fff',
  headerModelCardSecondary: '#adadad',
} as const;

const colorsJac = {
  headerBackground: '#fff',
  headerNav: '#1d1d1b',
  headerPhone: '#636466',
  headerPhoneHover: '#1d1d1b',
  headerPhoneButton: '#1d1d1b',
  headerPhoneButtonHover: '#860008',
  headerBurger: '#1d1d1b',
  headerBurgerClose: '#636466',
  headerModelCardPrimary: '#1d1d1b',
  headerModelCardSecondary: '#636466',
} as const;

const colorsJetour = {
  headerBackground: '#fff',
  headerNav: '#000',
  headerPhone: '#68a598',
  headerPhoneHover: '#628788',
  headerPhoneButton: '#000',
  headerPhoneButtonHover: '#628788',
  headerBurger: '#4c4c4c',
  headerBurgerClose: '#4c4c4c',
  headerModelCardPrimary: '#000',
  headerModelCardSecondary: '#4c4c4c',
} as const;

export const componentColors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsB2b;
  }
})();

export enum SubmenuTypes {
  Models = ':MODELS:',
}
