import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { media } from '@/utils/mixin';
import useSettingItem from '@/hooks/useSettingItem';
import { useDealerWebLinkClick } from '@/layout/hooks';

import { useDealerWebFooterMenu } from './hooks';
import MenuItem from './components/MenuItem';
import Contacts from './components/Contacts';
import Apps from './components/Apps';

function Menu() {
  const footerMenu = useDealerWebFooterMenu();
  const phonesSettings = useSettingItem('FOOTER_DEALER_WEB_PHONES');
  const emailsSettings = useSettingItem('FOOTER_DEALER_WEB_EMAILS');
  const appstoreLink = useSettingItem('FOOTER_DEALER_WEB_APPSTORE');
  const googlePlayLink = useSettingItem('FOOTER_DEALER_WEB_GOOGLE_PLAY');
  const { handleClickLink } = useDealerWebLinkClick();

  const phones =
    phonesSettings && phonesSettings.length !== 0
      ? phonesSettings.map(({ phone }) => phone ?? '')
      : [];
  const emails =
    emailsSettings && emailsSettings.length !== 0
      ? emailsSettings.map(({ email }) => email ?? '')
      : [];

  function handleClickMenuItem(
    link: Nullable<string>,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    handleClickLink(link, event);
  }

  const menuWithChildrens =
    footerMenu.withChildrens &&
    footerMenu.withChildrens.length > 0 &&
    footerMenu.withChildrens.map(
      ({ label, link, isNewTab, children }, index) => (
        <MenuColumn key={index}>
          <MenuItem
            label={label}
            link={link}
            isNewTab={isNewTab}
            children={children}
          />
        </MenuColumn>
      )
    );
  const menuWithoutChildrens = footerMenu.withoutChildrens &&
    footerMenu.withoutChildrens.length > 0 && (
      <MenuColumn>
        {footerMenu.withoutChildrens.map(({ label, link, isNewTab }, index) => (
          <MenuItemWrapper key={index}>
            <MenuItem
              label={label}
              link={link}
              isNewTab={isNewTab}
              onClick={handleClickMenuItem}
            />
          </MenuItemWrapper>
        ))}
      </MenuColumn>
    );

  return (
    <Component>
      {footerMenu.firstInOrder === 'withChildrens' ? (
        <React.Fragment>
          {menuWithChildrens}
          {menuWithoutChildrens}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {menuWithoutChildrens}
          {menuWithChildrens}
        </React.Fragment>
      )}

      {(phones.length !== 0 || emails.length !== 0) && (
        <MenuColumn>
          <Contacts phones={phones} emails={emails} />
        </MenuColumn>
      )}

      {(appstoreLink || googlePlayLink) && (
        <MenuColumn>
          <Apps
            appstore={appstoreLink ?? ''}
            googlePlay={googlePlayLink ?? ''}
          />
        </MenuColumn>
      )}
    </Component>
  );
}

export default Menu;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  `)}
`;

const MenuColumn = styled.div``;

const MenuItemWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }
`;
