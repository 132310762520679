import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import LogoImage from '@/assets/svg/atlant-logo.svg';
import Link from '@/components/Link';
import { useTypedDispatch } from '@/store/store';
import { handleMobileMenuClose } from '@/utils/common';
import { media } from '@/utils/mixin';

function Logo() {
  const isMobile = useMedia('(max-width: 768px)');
  const dispatch = useTypedDispatch();

  return (
    <Component
      onClick={() => {
        document.body.style.overflow = 'auto';
        handleMobileMenuClose(isMobile, dispatch);
      }}
    >
      <StyledLink to="/">
        <StyledLogoImage />
      </StyledLink>
    </Component>
  );
}

export default Logo;

const Component = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;
  margin-right: 15px;
  position: relative;

  @media (min-width: 1200px) {
    margin-right: 25px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const StyledLogoImage = styled(LogoImage)`
  width: 190px;
  height: 50px;

  ${media.mobile(css`
    width: 114px;
    height: 32px;
  `)}
`;
